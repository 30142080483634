import * as Sentry from "@sentry/nextjs";
import ExecutionEnvironment from "exenv";

// This can't be an environment variable as it's on the browser!
console.log("Starting Raven", process.env.NEXT_PUBLIC_SENTRY_DSN);

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Replay may only be enabled for the client-side
  integrations: [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

export function captureErrorResult(data: any) {
  console.error("Sentry capturing?", data);
  Sentry.captureException(new Error(JSON.stringify(data)));
}

export const captureError = (e: Error) => {
  Sentry.captureException(e);
};

if (ExecutionEnvironment.canUseDOM) {
  // @ts-ignore
  window["captureerror"] = captureError;
}

export const RavenClient = {
  captureBreadcrumb: (data: any) => {
    Sentry.addBreadcrumb(data);
  },
  captureMessage: (data: any, extra: any) => {
    Sentry.captureMessage(data, extra);
  },
};
export default captureError;
