"use client";

import { Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import cx from "classnames";
import { usePathname } from "next/navigation";
import * as React from "react";

import { NextLink } from "../../../common/Link/NextLink";
import { MobileOnly } from "../../helpers/mobile/MobileOnly";

type MenuLinkType =
  | {
      type: "link";
      href: string;
      children: React.ReactNode;
    }
  | {
      type: "externallink";
      href: string;
      children: React.ReactNode;
    }
  | {
      type: "divider";
    }
  | {
      type: "component";
      children: React.ReactNode;
    };
export type NavbarData = {
  isFullwidth?: boolean;
  logo: React.ReactNode;
  centerItem?: React.ReactNode;
  dropdown: {
    open: React.ReactNode;
    closed: React.ReactNode;
    links: Array<MenuLinkType>;
  } | null;
  desktop: {
    links: Array<MenuLinkType>;
  };
};

function MenuItems({ links }: { links: Array<MenuLinkType> }) {
  return (
    <>
      {links.map((link, i) => {
        switch (link.type) {
          case "link":
            return <MenuItem key={i} {...link} external={false} />;
          case "externallink":
            return <MenuItem key={i} {...link} external={true} />;
          case "divider":
            return <MenuDivider key={i} />;
          case "component":
            return <MenuComponent key={i} {...link} />;
          default:
            return null;
        }
      })}
    </>
  );
}

export function Navbar({ data }: { data: NavbarData }) {
  const menuDataDesktop = data.desktop.links;
  const dropdownBaseLinks = data.dropdown?.links ?? [];

  const menuDataMobile = [...dropdownBaseLinks];

  const displayDropdownButtonOnDesktop = dropdownBaseLinks.length > 0;

  const MenuItemsDesktop = <MenuItems links={menuDataDesktop} />;
  const MenuItemsMobile = <MenuItems links={menuDataMobile} />;

  const NavDesktopItems = (
    <>
      {menuDataDesktop.map((link, i) => {
        switch (link.type) {
          case "link":
            return (
              <NextLink
                key={i}
                applyLinkStyle={false}
                className="tw-flex tw-items-center tw-px-4 tw-text-gray-700 hover:tw-bg-gray-200 hover:tw-text-black hover:tw-border-gray-600 focus:tw-bg-gray-200 focus:tw-text-black focus:tw-border-gray-600"
                href={link.href}
              >
                {link.children}
              </NextLink>
            );
          case "externallink":
            return (
              <a
                key={i}
                className="tw-flex tw-items-center tw-px-4 tw-text-gray-700 hover:tw-bg-gray-200 hover:tw-text-black hover:tw-border-gray-600 focus:tw-bg-gray-200 focus:tw-text-black focus:tw-border-gray-600"
                target="_blank"
                href={link.href}
              >
                {link.children}
              </a>
            );
          case "component":
            return (
              <div
                className="tw-flex tw-items-center tw-px-4 tw-text-gray-700 hover:tw-bg-gray-200 hover:tw-text-black hover:tw-border-gray-600 focus:tw-bg-gray-200 focus:tw-text-black focus:tw-border-gray-600"
                key={i}
              >
                {link.children}
              </div>
            );
          default:
            return <div key={i} />;
        }
      })}
    </>
  );

  const Logo = data.logo;
  const CenterItem = data.centerItem;

  return (
    <>
      <Menu as={"div" as const} id="header_navbar">
        {({ open }) => (
          <>
            <div className="tw-bg-white tw-border-b tw-border-gray-300">
              <div
                className={cx(
                  "tw-relative tw-h-14 tw-grid tw-grid-cols-[1fr_56px] tw-items-center",
                  data.isFullwidth
                    ? "tw-ml-2 tw-mr-4 lg:tw-mx-6"
                    : "xl:tw-container tw-mx-auto tw-px-4 xl:tw-px-16",
                  CenterItem
                    ? "md:tw-grid-cols-[1fr_128px_1fr]"
                    : "md:tw-grid-cols-[1fr_32px_1fr]"
                )}
              >
                <div className="tw-flex tw-items-center tw-font-medium">
                  {Logo}
                </div>
                <div className="tw-hidden md:tw-block">{CenterItem}</div>
                <nav className="tw-h-full tw-justify-self-end">
                  <div className="tw-h-full tw-flex">
                    <div className="tw-h-full tw-hidden md:tw-flex">
                      {NavDesktopItems}
                    </div>
                    <div
                      className={cx(
                        "tw-h-full",
                        displayDropdownButtonOnDesktop ? null : "md:hidden"
                      )}
                    >
                      <Menu.Button
                        id={`header_menu_button`}
                        className="tw-flex tw-h-full tw-justify-center tw-items-center tw-px-4 -tw-mr-4 focus:tw-bg-gray-200 hover:tw-bg-gray-200"
                      >
                        {open ? (
                          <>
                            <span className="tw-hidden md:tw-inline">
                              {data.dropdown?.closed}
                            </span>
                            <span className="tw-inline md:tw-hidden">
                              <XIcon
                                className="tw-h-6 tw-w-6"
                                aria-label="Close menu"
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="tw-hidden md:tw-block">
                              {data.dropdown?.open}
                            </span>
                            <span className="tw-block md:tw-hidden">
                              <MenuIcon
                                className="tw-h-6 tw-w-6"
                                aria-label="Open menu"
                              />
                            </span>
                          </>
                        )}
                      </Menu.Button>
                    </div>
                  </div>
                  <div
                    className={cx(
                      `tw-absolute tw-origin-top-left tw-left-0 tw-top-0 tw-w-full tw-mt-16 tw-z-20 xs:tw-origin-top-right xs:tw-left-auto xs:tw-top-0 xs:tw-right-0 xs:tw-w-64`,
                      `tw-block`,
                      !displayDropdownButtonOnDesktop && `md:tw-hidden`
                    )}
                  >
                    <Transition
                      show={open}
                      enter="tw-transition tw-duration-100 tw-ease-out"
                      enterFrom="tw-transform tw-scale-95 tw--translate-y-4 tw-opacity-0"
                      enterTo="tw-transform tw-scale-100 tw-opacity-100"
                      leave="tw-transition tw-duration-75 tw-ease-out"
                      leaveFrom="tw-transform tw-scale-100 tw-opacity-100"
                      leaveTo="tw-transform tw-opacity-0"
                    >
                      {/* TODO: Confirm margin */}
                      <Menu.Items
                        static
                        className="tw-bg-white tw-shadow-lg tw-rounded tw-py-1 tw-w-full md:tw-origin-top-right md:tw-right-0 md:tw-mt-2 md:tw-mr-2 focus:tw-outline-none"
                      >
                        <MobileOnly>{MenuItemsDesktop}</MobileOnly>
                        {MenuItemsMobile}
                      </Menu.Items>
                    </Transition>
                  </div>
                </nav>
              </div>
            </div>
          </>
        )}
      </Menu>
      {/* <div className="tw-flex tw-items-center tw-justify-center tw-h-5 tw--mb-5 tw-w-full tw-uppercase tw-text-xs tw-text-white tw-overflow-hidden tw-font-medium">
        <span className="tw-bg-orange-400 tw-rounded-b tw-px-2">
          <Link href="/host/settings/payment">Demo Mode</Link>
        </span>
      </div> */}
    </>
  );
}

function MenuItem({
  children,
  href,
  external,
}: {
  children: React.ReactNode;
  href: string;
  external: boolean;
}) {
  const pathname = usePathname()!;
  const itemIsThisPage = href === pathname;

  if (external) {
    return (
      <Menu.Item>
        {({ active }) => (
          <a
            target="_blank"
            href={href}
            className={cx(
              "tw-px-4 tw-py-2 tw-my-1 tw-text-gray-700 tw-border-l-4 tw-border-transparent tw-cursor-pointer",
              "tw-block",
              itemIsThisPage
                ? "tw-font-bold tw-text-blue-800 tw-bg-blue-200 tw-border-blue-600"
                : [active && "tw-text-black tw-bg-gray-200 tw-border-gray-600"]
            )}
          >
            {children}
          </a>
        )}
      </Menu.Item>
    );
  } else {
    return (
      <Menu.Item>
        {({ active }) => {
          return (
            <NextLink
              href={href}
              className={cx(
                "tw-px-4 tw-py-2 tw-my-1 tw-text-gray-700 tw-border-l-4 tw-border-transparent tw-cursor-pointer",
                "tw-block",
                itemIsThisPage
                  ? "tw-font-bold tw-text-blue-800 tw-bg-blue-200 tw-border-blue-600"
                  : [
                      active &&
                        "tw-text-black tw-bg-gray-200 tw-border-gray-600",
                    ]
              )}
              applyBaseStyle={false}
            >
              {children}
            </NextLink>
          );
        }}
      </Menu.Item>
    );
  }
}

function MenuComponent({ children }: { children: React.ReactNode }) {
  return (
    <Menu.Item>
      {({ active }) => (
        <div
          className={cx(
            "tw-px-4 tw-py-2 tw-my-1 tw-text-gray-700 tw-border-l-4 tw-border-transparent tw-cursor-pointer",
            "tw-block",
            [active && "tw-text-black tw-bg-gray-200 tw-border-gray-600"]
          )}
        >
          {children}
        </div>
      )}
    </Menu.Item>
  );
}

function MenuDivider() {
  return <hr className="tw-my-2" />;
}
