import { cx } from "class-variance-authority";
import * as React from "react";
// @ts-expect-error react-copy-to-clipboard any
import CopyToClipboard from "react-copy-to-clipboard";

import { InferPropTypes } from "../../utilities/InferPropTypes";
import { Button } from "./Button/Button";
import { IconShim } from "./IconShim";

export function ButtonCopy({
  text,
  children,
  ...props
}: { text: string } & InferPropTypes<typeof Button>) {
  const [copied, setCopied] = React.useState(false);

  const hasButtonText = children?.toString && children?.toString.length > 0;

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      <Button color="blue" {...props}>
        <span>
          <IconShim
            className={cx(hasButtonText ? "tw-mr-2" : "")}
            icon={copied ? "check" : "copy"}
          />
          {children}
        </span>
      </Button>
    </CopyToClipboard>
  );
}

export default ButtonCopy;
